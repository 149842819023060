import React, { useContext } from 'react'
import Navigation from './Nav/Navigation'
import {Routes, Route } from 'react-router-dom';
import Home from './Nav/Views/Home/Home';
import Contact from './Contact/Contact';
import Weapons from './Nav/Views/Weapons/Weapons';
import Packages from './Nav/Views/Packages/Packages';
import AboutUs from './Nav/Views/LilSites/AboutUs';
import Membership from './Nav/Views/LilSites/Membership';
import Login from './Nav/Views/Login';
import Admin from './Nav/Views/AdminPanel/Admin';
import AuthContext from '../AuthContext';
import MembershipContacts from "./Nav/Views/AdminPanel/MembershipContacts"
import Settings from './Nav/Views/AdminPanel/Settings';
import Booking from './Nav/Views/Booking';
import EditWeapons from './Nav/Views/AdminPanel/EditWeapons';
import EditPackages from './Nav/Views/AdminPanel/EditPackages';
import PrivacyPolicy from './Nav/Views/Inform/PrivacyPolicy';
import TermsAndConditions from './Nav/Views/Inform/TermsAndConditions';
import ReturnsPolicy from './Nav/Views/Inform/ReturnsPolicy';

export default function Public() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div>
      <Navigation></Navigation>
      <Routes>
          <Route exact path='/' element={<Home></Home>}></Route>
          <Route exact path='spr' element={<Booking></Booking>}></Route>
          <Route exact path='/contact' element={<></>}></Route>
          <Route exact path='/weapons' element={<Weapons></Weapons>}></Route>
          <Route exact path='/packages' element={<Packages></Packages>}></Route>
          <Route exact path='/about-us' element={<AboutUs></AboutUs>}></Route>
          <Route exact path='/membership' element={<Membership></Membership>}></Route>
          <Route exact path='/login' element={<Login></Login>}></Route>
          <Route exact path='/booking/:id' element={<Booking></Booking>}></Route>
          <Route exact path='/policy' element={<PrivacyPolicy/>}></Route>
          <Route exact path='/terms-and-conditions' element={<TermsAndConditions/>}></Route>
          <Route exact path='/returns-policy' element={<ReturnsPolicy/>}></Route>
          {isLoggedIn && <Route exact path='/admin' element={<Admin></Admin>}></Route>}
          {isLoggedIn && <Route exact path='/settings' element={<Settings></Settings>}></Route>}
          {isLoggedIn && <Route exact path='/memberships-contacts' element={<MembershipContacts></MembershipContacts>}></Route>}
          {isLoggedIn && <Route exact path='/edit/weapons' element={<EditWeapons></EditWeapons>}></Route>}
          {isLoggedIn && <Route exact path='/edit/packages' element={<EditPackages></EditPackages>}></Route>}
          <Route path="*" element={<div style={{color:"red",opacity:0.8,fontSize: "4em",textAlign: "center",marginBottom:"2em"}}>404</div>} />
      </Routes>
      {!isLoggedIn && <Contact></Contact>}
    </div>
  )
}
