import React, { useState } from 'react'
import classes from "./Home.module.css"
import bullet from './bullet.png'
import ak from "./ak74.png"
import ppsh from "./ppsh.png"
import track from "./track.png"
import { useNavigate } from 'react-router-dom'
import {Helmet} from "react-helmet";

export default function Home() {
  const weapons = ["M4 A4","M16","G36","STEYR AUG","MK12 mod1 SPR","KALASHNIKOV AKM","AK74","KALASHNIKOV AKMS","YUGOSLAVIAN M92","PSL-54C","PPSh-41","M1 carbine","M14","Heckler&Koch G3","Remington M1100","SPAS-15","Remington M870 tac.","Enfield No. 1 Mk. 3","Long Branch No.1 Mk. 4.","MP5","UZI","Mini UZI","Roni system (GLOCK)","Scorpion Vz. 61"]
  const [headerText] = useState("The most advanced shooting range in Budapest")

  const navigate = useNavigate()

  const handleNavigateToBook = () => {
    navigate("/packages#booking")
  }
  

  return (
    <div className={classes.Home}>
      <Helmet>
        <title>Top Gun Shooting Range Budapest</title>
        <meta name="description" content="Experience a unique shooting range in Budapest. Book a session to try a variety of weapons, from Uzi to sniper rifles." />
        <meta name="keywords" content="Shooting range,budapest gun range,budapest gun club,Budapest shooting range,battle riffle,weapons,Shotgun,Battle riffles,Budapest shoot,Budapest shooting range,shooting competitions,firearm rental Budapest,tactical shooting,target shooting,sport shooting,shooting events in Budapest" />
      </Helmet>

      <div className={classes.movingDiv}>
        {weapons.map(x => {
          return <p key={x}>{x}</p>
        })}
      </div>
      <div className={classes.movingDiv}>
        {weapons.map(x => {
          return <p key={x}>{x}</p>
        })}
      </div>

      <div className={classes.content}>
        <button onClick={()=>handleNavigateToBook()} className={classes.book}>Book now</button>
        <h1 className={classes.lilabout}><b>{headerText}</b>
        <br/> <b>The training you receive is coming from true experts.</b>
        <br/>We have instructors from a variety of special forces<br/> <b>all over the world and cover multiple languages</b>
        </h1>
        <div className="d-flex flex-row gap-3 mb-1">
          <img height="30px" width="30px" src={bullet} alt="bullet" />
          <img height="30px" width="30px" src={bullet} alt="bullet" />
          <img height="30px" width="30px" src={bullet} alt="bullet" />
        </div>
        <p className={classes.lilabout}>Top Gun shooting range Budapest can<br/>accommodate both beginners and experts
        <br/>offering everything from <b>Uzi</b> to <b>Mp5</b> all the way up to <br/> <b>Sniper Class</b> weapons and even <b>Heavy duty</b> Machine Guns.</p>
      </div>
      <div className={classes.stats}>
        <div className={classes.TrackBack}>
          <img src={track} className={classes.Track} alt="Track" />
          <img src={track} className={classes.Track} alt="Track" />
        </div>
        <div className={classes.guns}>
          <img src={ak} width="500px" alt="AK-74 Mockup" />
          <img src={ppsh} width="500px" alt="PPSH Mockup" />
        </div>
        <h3>OUR WEAPONS ARE AT YOUR DISPOSAL</h3>
        <p>We have a huge variety of weapons for you to try at our one-of-a-kind shooting range in Budapest. From old school rifles from 1898 to World War 1 & 2 all the way up to Modern day sniper rifles. Whatever your taste or experience Top Gun Shooting Budapest has you covered</p>
        <div className={classes.Kardtyavar}>
          <div className={classes.Kardtya}>
            <div className={classes.lilCard}>
              <div className={classes.Backdrop}></div>
              <h1>8</h1>
              <p>Experience packages</p>
            </div>
          </div>
          <div className={classes.Kardtya}>
            <div className={classes.lilCard}>
              <div className={classes.Backdrop}></div>
              <h1>+200</h1>
              <p>Different weapons</p>
            </div>
          </div>
          <div className={classes.Kardtya}>
            <div className={classes.lilCard}>
              <div className={classes.Backdrop}></div>
              <h1>4</h1>
              <p>Shooting lanes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
