import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classes from './admin.module.css';

export default function EditWeapons() {
  const [formData, setFormData] = useState({
    _id: null,
    weaponImg: null,
    name: '',
    caliber: '',
    inService: '',
    description: '',
    weaponType: 'ASSOULT RIFFLES'
  });
  const [isEdit,setIsEdit] = useState(false)
  const token = localStorage.getItem('token')
  const [publicWeapons,setPublicWeapons] = useState(null)

  useEffect(() => {
    getPublicWeapons()
  }, [])
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const scrollToTop = () => {
    document.getElementById('scroll-to-top-ew-js').scrollIntoView({behavior:'smooth'})
  }

  const handleFileChange = (e) => {
    const newFormData = formData
    newFormData.weaponImg = e.target.files[0]
    setFormData(newFormData);
  };

  const deletePublicWeapon = async (id) => {
      const isSure = window.confirm('Are you sure you want to delete this weapon?');
      if(!isSure) return;

      const config = { headers: { Authorization: `Bearer ${token}` }};
      await axios.delete(`/api/v1/public-weapons/`+id,config).catch(err => err);
      getPublicWeapons();
  }

  const getPublicWeapons = async () => {
      const config = { headers: { Authorization: `Bearer ${token}` }};
      const response = await axios.get(
      `/api/v1/public-weapons`,config
      ).catch(e => console.log(e));
      const d = response.data;
      setPublicWeapons(d)
  }

  const editPublicWeapon = (id) => {
      scrollToTop()
      const wep = publicWeapons.find(x => x._id === id)
      setFormData(wep)
      setIsEdit(true)
  }
  
  const handleCancel = () => {
    setFormData({
      weaponImg: null,
      name: '',
      caliber: '',
      inService: '',
      description: '',
      weaponType: ''
    });
    setIsEdit(false)
  }

  const createOrUpdateWeapon = async () => {
    const token = localStorage.getItem("token");
    const config = { headers: { Authorization: `Bearer ${token}` }};

    try {
      if(isEdit){
        const formDataToSend = new FormData();
        formDataToSend.append('weaponImg', formData.weaponImg);
        formDataToSend.append('name', formData.name);
        formDataToSend.append('caliber', formData.caliber);
        formDataToSend.append('inService', formData.inService);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('weaponType', formData.weaponType);
        const id = publicWeapons.find(x => x._id === formData._id)._id
        await axios.put('/api/v1/public-weapons/'+id, formDataToSend, config);
        getPublicWeapons()
      }else{
        const formDataToSend = new FormData();
        formDataToSend.append('weaponImg', formData.weaponImg);
        formDataToSend.append('name', formData.name);
        formDataToSend.append('caliber', formData.caliber);
        formDataToSend.append('inService', formData.inService);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('weaponType', formData.weaponType);
        const response = await axios.post('/api/v1/public-weapons', formDataToSend, config);

        if (response.status === 201) {
          setFormData({
            weaponImg: null,
            name: '',
            caliber: '',
            inService: '',
            description: '',
            weaponType: ''
          });
          getPublicWeapons()
        } else {
          console.error('Failed to create weapon');
        }
      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.editWeapons} id="scroll-to-top-ew-js">
      <div className='row'>
        <div className='col-12 mb-3'>
          <h1><b>{isEdit ? 'Edit' : 'Add'} Weapon</b></h1>
        </div>
        <div className='col-3 mb-3'>
          <label>Image</label>
          <input type="file" name="weaponImg" id="weaponImg" onChange={(e)=>handleFileChange(e)} />
        </div>
        <div className="col-3">
          <img src={formData.weaponImg} alt="img" width="100px" />
        </div>
        <div className="col-6">
          <label>Weapon type</label>
          <select value={setFormData.weaponType} name="weaponType" onChange={handleInputChange}>
            <option value="ASSAULT RIFFLES">Assault Riffles</option>
            <option value="BATTLE RIFLES">Battle Riffles</option>
            <option value="SUBMACHINE GUNS">Submachine guns</option>
            <option value="RIFFLES">Riffles</option>
            <option value="SHOTGUNS">Shotguns</option>
            <option value="MACHINE GUNS">Machine guns</option>
          </select>
        </div>
        <div className='col-4'>
          <label>Name</label>
          <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </div>
        <div className='col-4'>
          <label>Caliber</label>
          <input type="text" name="caliber" value={formData.caliber} onChange={handleInputChange} />
        </div>
        <div className='col-4'>
          <label>In service</label>
          <input type="text" name="inService" value={formData.inService} onChange={handleInputChange} />
        </div>
        <div className='col-12 d-flex flex-column mt-2'>
          <label>Description</label>
          <textarea name="description" rows="5" value={formData.description} onChange={handleInputChange}></textarea>
        </div>
        <hr className='mt-4' />
        <div className='col-4'>
          <input type="button" value={ isEdit ? "Save" : "Create new" } onClick={createOrUpdateWeapon} />
        </div>
          {isEdit && <div className='col-2'>
            <input type="button" className='secondary' value="Cancel" onClick={handleCancel} />
          </div>
           }
        <div className='col-12 mt-5'>
          <h1><b>Weapons</b></h1>
        </div>
        <div className='col-12 mt-3 d-flex gap-2 flex-column'>
          {
            publicWeapons && publicWeapons.map(publicWeapon => {
              return (
                <div className={['row',classes.publicWeapon].join(' ')} key={publicWeapon._id}>
                  <div className='col-2'>
                    <img className={classes.weaponImg} width="100%" src={publicWeapon.weaponImg} alt="" />
                  </div>
                  <div className="col-7">
                    <p className='m-0'><b>Name: </b>{publicWeapon.name}</p>
                    <div className='d-flex flex-row gap-1'><p className="m-0 gap-2 d-flex flex-row"><b>Type:</b></p><p className={classes.firstLetterUppercase}>{publicWeapon.weaponType.toLowerCase()}</p></div>
                    <p className='m-0'><b>Caliber:</b> {publicWeapon.caliber}</p>
                    <p className='m-0'><b>In Service:</b> {publicWeapon.inService}</p>
                  </div>
                  <div className="col-3 d-flex flex-start gap-2">
                    <input type="button" onClick={()=> editPublicWeapon(publicWeapon._id)} value="Edit" />
                    <input type="button" className='secondary' onClick={(e)=>deletePublicWeapon(publicWeapon._id)} value="Delete" />
                  </div>
                </div>
            )
            })
          }
        </div>
      </div>
    </div>
  );
}
