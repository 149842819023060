export const AlertTemplate = ({ style, options, message, close }) => {
    const alertStyle = {
        zIndex: 10000000,
        backgroundColor: "#88110f",
        color: "whitesmoke",
        minHeight: "50px",
        width: "300px",
        padding: "20px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "2em",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: ".8em",
        borderBottom: "2px solid #f7a8a7",
        marginTop: "3em"
    }

    return (
        <div style={alertStyle}>
          {message}
        </div>
    )
}