import React, { useState } from "react";
import classes from "./LilSites.module.css";
import axios from "axios"
import { useAlert } from 'react-alert'

export default function Membership() {
  const initFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    birthday: '',
    nationality: '',
    company: '',
    occupationTitle: '',
    whereDidYouHear: '',
    comment: '',
  }
  const isMobile = window.innerWidth <= 767;
  const [formData, setFormData] = useState(initFormData);
  const alert = useAlert()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post('/api/v1/membership', formData);
        if(response.status === 200){
          alert.show('Your membership registration is successfully submitted!')
          setFormData(initFormData)
        }
      } catch (error) {
        console.error(error);
      }
  };

  return (
    <div className={classes.Membership}>
      <div className="mt-3 d-flex flex-column gap-5">
        <h1>Top Gun Members</h1>
        <div className="d-flex flex-column gap-3">
          <div>
            <a
              data-bs-toggle="collapse"
              href="#op1"
              aria-expanded="false"
              aria-controls="op1"
            >
              <h2>What is a Top Gun Membership?</h2>
            </a>
            <div className={isMobile ? "collapse" : ""} id="op1">
              <p>
                Top Gun Membership (CM) is a closed group for individuals who
                share similar interests, founded with a shooting range. CM has
                grown to have numerous partnerships across Hungary. Owning a CM
                will not only bring numerous discounts but also a lot of
                networking opportunities. Your CM membership is designed to
                develop long-lasting relationships that deepen and evolve
                through our core values.
              </p>
            </div>
          </div>
          <div>
            <a
              data-bs-toggle="collapse"
              href="#op2"
              aria-expanded="false"
              aria-controls="op2"
            >
              <h2>What we offer</h2>
            </a>
            <div className={isMobile ? "collapse" : ""} id="op2">
              <p>
                A unique way to network and socialize, we host quartile events
                for members only and invite our collaborative partners to also
                attend. This breeds an opportunity for individuals to meet new
                contacts and fruitfully form their own network whether for
                business or pleasure. Top Gun members will also gain access to
                our exclusive whisky and cigar room at our shooting range. A
                place where members can come and relax after a tough day at the
                office.
              </p>
            </div>
          </div>
          <div>
            <a
              data-bs-toggle="collapse"
              href="#op3"
              aria-expanded="false"
              aria-controls="op3"
            >
              <h2>Who we are looking for?</h2>
            </a>
            <div className={isMobile ? "collapse" : ""} id="op3">
              <p>
                Professional individuals from all walks of life. Top Gun
                Membership is not aimed at just one group, in fact, the opposite
                we have a diverse range of members from all walks of life which
                complements the networking opportunities. All we ask is our
                potential new members to complete the application form for
                approval, from there we will take the next steps.
              </p>
            </div>
          </div>
          <div>
            <a
              data-bs-toggle="collapse"
              href="#op4"
              aria-expanded="false"
              aria-controls="op4"
            >
              <h2>Benefits</h2>
            </a>
            <div className={isMobile ? "collapse" : ""} id="op4">
              <p>
                The benefits list and is always growing, members will get
                exclusive discounted round prices and free access to use the
                range within our operating hours. They will also be able to
                shoot during our members-only timings. You may also bring one
                guest to each quarterly meeting and on that day share the
                benefits with your guest. Monthly promotion email: Members will
                be sent an email each month with a free promotion from Top Gun
                shooting along with any discounts or promotions from our
                partners. The retail price of these gifts will more than cover
                your annual membership fee. NOT TO BE MISSED!
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-3 mb-5 md-md-0">
              <h2>Our partners</h2>
              <ul>
                <li>Becketts Irish Bar</li>
                <li>GoodSpirit Bar</li>
                <li>whiskynet.hu</li>
                <li>Royal Diamonds</li>
                <li>Hotsy Totsy</li>
                <li>Caviar & Bull</li>
                <li>The Beaver</li>
              </ul>
            </div>
            <div className="col-12 col-md-9 mb-5 md-md-0">
              <div className={classes.Registration}>
                <h2>Registration</h2>
                <p>
                  If you want to be a member, please fill out the form below and
                  we'll contact you.
                </p>
                <form className="row" onSubmit={handleSubmit}>
                  <div className="col-6">
                    <p>First Name</p>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <p>Last Name</p>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <p>Email address</p>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <p>Phone</p>
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6">
                    <p>Birthday</p>
                    <input
                      type="text"
                      name="birthday"
                      value={formData.birthday}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <p>Nationality</p>
                    <input
                      type="text"
                      name="nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <p>Company</p>
                    <input
                      type="text"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <p>Occupation title</p>
                    <input
                      type="text"
                      name="occupationTitle"
                      value={formData.occupationTitle}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <p>Where did you hear about us?</p>
                    <input
                      type="text"
                      name="whereDidYouHear"
                      value={formData.whereDidYouHear}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <p>Comment (optional)</p>
                    <input
                      type="text"
                      name="comment"
                      value={formData.comment}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 justify-content-end d-flex">
                    <input
                      type="submit"
                      className={classes.submit}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
