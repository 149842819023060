import { useEffect, useState } from 'react';
import './App.css';
import Public from './Public/Public';
import AuthContext from './AuthContext';
import axios from "axios";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import {AlertTemplate} from './Public/Utils/Alert'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const alertOptions = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE
  }

  useEffect(() => {
    const checkLoggedIn = async() => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const config = { headers: { Authorization: `Bearer ${token}`, }, };
          const response = await axios.get("/api/v1/is-valid", config);
          const isLoggedIn = response.data;
          setIsLoggedIn(isLoggedIn);
        } else {
          setIsLoggedIn(false)
        }
      } catch (error) {
        setIsLoggedIn(false)
      }
    };
    checkLoggedIn();
  }, []);

  //test mode div
  //<div className='test-alert'>Please be aware that this website is currently operating in test mode</div>

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <div className="App">
          <Public></Public>
        </div>
      </AlertProvider>
    </AuthContext.Provider>
  );
}

export default App;
