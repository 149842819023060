import React, { useState , useEffect } from 'react'
import classes from "./admin.module.css"
import axios from "axios"
import moment from "moment"

export default function MembershipContacts() {
  const [memberships,setMemberships] = useState([])
  const [contacts,setContacts] = useState([])

  useEffect(() => {
    async function fetchData(){
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const config = { headers: { Authorization: `Bearer ${token}` } };
          var response = await axios.get(
            `/api/v1/get-memberships`,
            config
          );
          var d = response.data;
          if (d) {
            setMemberships(d);
          }

          response = await axios.get(
            `/api/v1/get-contacts`,
            config
          );
          d = response.data;
          if (d) {
            setContacts(d);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        window.location.href = "/";
      }
    }
    fetchData()
  }, [])
  

  return (
    <div className={classes.membershipContacts}>
      <section>
      <h1> <b>Membership form submits</b></h1>
        <table className="table table-warning table-dark table-hover">
          <tbody>
            <th>First name</th>
            <th>Last name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Birthday</th>
            <th>Nationality</th>
            <th>Company</th>
            <th>Occupation</th>
            <th>Where did you hear?</th>
            <th>Comment</th>
           {
            memberships.map(membership => {
              return (<tr>
                  <td>{membership.firstName}</td>
                  <td>{membership.lastName}</td>
                  <td>{membership.email}</td>
                  <td>{membership.phone}</td>
                  <td>{moment(membership.birthday).format("YYYY-MM-DD")}</td>
                  <td>{membership.nationality}</td>
                  <td>{membership.company}</td>
                  <td>{membership.occupationTitle}</td>
                  <td>{membership.whereDidYouHear}</td>
                  <td>{membership.comment}</td>
              </tr>)
            })
           }
          </tbody>
        </table>
      </section>
      <section>
      <h1> <b>Contact form submits</b></h1>
        <table className="table table-warning table-dark table-hover">
          <tbody>
            <th>Full Name</th>
            <th>Email</th>
            <th>Message</th>
           {
            contacts.map(contact => {
              return (<tr>
                  <td>{contact.fullName}</td>
                  <td>{contact.email}</td>
                  <td>{contact.message}</td>
              </tr>)
            })
           }
          </tbody>
        </table>
      </section>
    </div>
  )
}
