import React, { useEffect, useMemo, useState } from 'react'
import classes from "./Packages.module.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios"
import moment from "moment"
import accepted_cards from "./accepted_cards.png"
import { useLocation, useNavigate} from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {Helmet} from "react-helmet";
import { useAlert  } from 'react-alert';

export default function Packages() {
    const isMobile = window.innerWidth <= 767;
    const [startDate, setStartDate] = useState(new Date());
    const [packages,setPackages] = useState([]);
    const [extraPackages,setExtraPackages] = useState([])
    //packages
    const [extras,setExtras] = useState([])
    const [mainSelected,setMainSelected] = useState([])
    //price
    const [price,setPrice] = useState(0)
    const [eurPrice,setEurPrice] = useState(null)
    //price end
    const [filteredTime,setFilteredTime] = useState([])
    const [numbers,setNumbers] = useState(Array.from({ length: 21 }, (_, index) => index))
    const location = useLocation()
    const [flipped,setFlipped] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    //validation
    const [validationError,setValidationError] = useState(null)
    const [dayAvailability, setDayAvailability] = useState(0)
    const availablePeopleCount = useMemo(() => {
      const extraCount = extras?.reduce((value,next)=>{
        return value + next.quantity
      },0)
      const mainCount = mainSelected?.reduce((value,next)=>{
        return value + next.quantity
      }, 0)

      const pkgCount = extraCount + mainCount;
      if(dayAvailability >= pkgCount){
        return pkgCount
      }else{
        return dayAvailability
      }
    }, [extras, mainSelected, dayAvailability]);
    useEffect(()=>{
      setFormData(prevFormData => ({
        ...prevFormData,
        howManyPeople: 0
      }));
    },[availablePeopleCount])
    //availability
    const alert = useAlert()

    var hoursArray = [
        "Select hour",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00"
      ];
      const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        streetAddress: '',
        postCode: '',
        city: '',
        country: '',
        message: '',
        promoCode: '',
        termsAndConditions: '',
        time: 10,
        howManyPeople: 0,
        currency: 'HUF'
        });

        const handleChange = (e) => {
            const { name, value, type, checked } = e.target;
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: type === 'checkbox' ? checked : value
            }));
        };

    function isCardOpened(name){
        const target = flipped.find(x => x.name === name)
        return flipped && target && target.isOpened
    }

    useEffect(()=>{
        fetchEurprice()
    },[price])

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      'time': 'Select hour'
    }));
  }, [startDate])

    const fetchEurprice = async () => {
        let currentEur = await axios.get('/api/v1/current-eur')
        currentEur = currentEur.data.eur
        if(currentEur){
            setEurPrice(price / currentEur)
            const priceTxt = getPrice()
        }
    }

    const getPrice = () => {
        if(formData.currency === 'EUR'){
            if(eurPrice){
                return eurPrice?.toFixed(2) + ' EUR'
            }
        }else{
            return price + ' HUF'
        }
    }

    const fetchPackages = async () => {
        const responseObj = await axios.get('api/v1/packages')
        if(!responseObj.data) return;
        
        //SET ONLY MAIN PACKAGES
        setPackages(responseObj.data.filter(x => x.packageType !== 'EXTRA').map(x => {
            return {
                name: x.name,
                price: x.price,
                totalRoundCount: x.weapons.reduce((a,b) => a + b.roundCount ,0),
                properties: x.weapons.map(y => {
                    return {
                        roundCount : y.roundCount,
                        roundType: y.roundType,
                        weapon: y.weapon.name,
                    }
                }),
                id: x._id
            }
        }))
        setExtraPackages(responseObj.data.filter(x => x.packageType === 'EXTRA').map(x => {
            return {
                name: x.name,
                price: x.price,
                totalRoundCount: x.weapons.reduce((a,b) => a + b.roundCount ,0),
                properties: x.weapons.map(y => {
                    return {
                        roundCount : y.roundCount,
                        roundType: y.roundType,
                        weapon: y.weapon.name
                    }
                })
            }
        }))
    }

    useEffect(()=>{
        fetchEurprice()
        fetchPackages()
    },[])

      const filterDate = (date) => {
        return date
      }

    useEffect(() => {
        async function fetchData(){
            try {
                const bookingDate = moment(startDate).format("YYYY-MM-DD")
                const now = moment().format("YYYY-MM-DD")
                const isToday = moment(now).isSame(bookingDate)
                let filteredDates = []

                if(isToday){
                    filteredDates = filteredDates.concat(Array.from({length: (new Date().getHours()+2)}).map((x,i) => i))
                }
                let availability
                if(formData.time !== 'Select hour') {
                  availability = await axios.get(`api/v1/get-availability?date=${bookingDate}&time=${formData.time}`);
                }
                const notAvaiableTimes = await axios.get(`api/v1/get-excluded-time?date=${bookingDate}`);  
                const filterDatesOut = filteredDates.concat(notAvaiableTimes.data)

                if(availability) {
                  setDayAvailability(availability.data)
                  setNumbers(Array.from({ length: availability.data + 1 }, (_, index) => index))
                }
                setFilteredTime(filterDatesOut)
            } catch (error) {
                console.log(error) 
            }
        }
        fetchData()
    }, [formData.time,startDate])
    

    useEffect(() => {
        if (location.hash === "#booking") {
            setTimeout(() => {
                document.getElementById("booking").scrollIntoView({ behavior: "smooth" });
            }, 500); 
        }
    }, [location]);

    const handleBook = () => {
        try {
            const error = isBookingFormValid()
            if(!error){
                setValidationError(null)
                setShowPopup(true);
            }else{
                setValidationError(error)
            }
        } catch (error) {
          console.error(error);
        }
    };

    const startBook = async () => {
        try{
            const mainPackages = mainSelected.map(selected => ({...selected, isMain: true}))
            const selected = mainPackages.concat(extras)
            const bookingData = {...formData,selected,date: startDate}
            const response = await axios.post('api/v1/book', bookingData);
            const acceptedStatuses = [200,201]

          if(acceptedStatuses.includes(response.status)){
                setShowPopup(false)
                window.location.assign(response.data.url)
            }else{
                setShowPopup(false)
                alert.show('Service temporary not available...')
            }
        }catch(error){
            setShowPopup(false)
            alert.show('Service temporary not available...')
        }
    }

    function isBookingFormValid(){
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

        if(formData.time === 'Select hour'){
            return "Please provide a time for your booking."
        }
        if(extras.length && (mainSelected.length === 0)){
            return "To apply an Extra package, you must select at least 1 Main package. Please choose a Main package before adding an Extra package to your selection."
        }
        if(mainSelected.length === 0 || price === 0){
            return "Please choose a Main package."
        }
        if(Number(formData.howManyPeople) === 0){
            return "You can not book for zero people."
        }
        if(!formData.termsAndConditions){
            return "Terms and Conditions Agreement Required"
        }
     
        if((!formData.email && (formData.email.length === 0)) || !(formData.email).match(emailRegex)){
            return "Please enter a valid email address."
        }

        const requiredFields = {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          phoneNumber: 'Phone Number',
          streetAddress: 'Street Address',
          postCode: 'Post Code',
          city: 'City',
          country: 'Country'
        };

        for (const field in requiredFields) {
          if (!formData[field] || formData[field].trim() === '') {
            return `Please enter a valid ${requiredFields[field]}.`;
          }
        }

        return null
  }

        const handleSelect = (name,p) => {
            const alreadyIn = mainSelected.find(x => x.name === name)
        if (alreadyIn) {
            const updatedSelected = mainSelected.filter((item) => item.name !== name);
            setMainSelected(updatedSelected);
          } else {
            const packageInstance = packages.find(x => x.name === name)
            if(!packageInstance) return

            const updatedSelected = [...mainSelected, {
                ...packageInstance,
                quantity: 1
            }];
            setMainSelected(updatedSelected);
          }
      };

      const handleSelectExtra = (name,p) => {
            const alreadyIn = extras.find(x => x.name === name)
        if (alreadyIn) {
            const a = extras.filter((item) => item.name !== name);
            setExtras(a)
          } else {
            const packageInstance = extraPackages.find(x => x.name === name)
            if(!packageInstance) return
            const a = [...extras, {
                ...packageInstance,
                quantity: 1
            }];
            setExtras(a);
          }
      };

      const navigate = useNavigate()

      const handleNavigateToBook = () => {
        navigate("/packages#booking")
      }

      function getPackageItemClass(name){
        const isIn = mainSelected.find(x => x.name === name) || extras.find(x => x.name === name)
        let classNames = 'col-12 col-lg-12'
        if(isIn){
            classNames = 'col-8 col-lg-9'
        }
        return [classes.checkOutCard,isIn ? classes.selected : null,classNames]
      }

      function getPackageItemQuantityClass(name){
        const isIn = mainSelected.find(x => x.name === name) || extras.find(x => x.name === name)
        let classNames = 'd-none'
        if(isIn){
            classNames = 'col-4 col-lg-3 p-0'
        }
        return [classNames]
      }

      function onPackageQuantityChange(event, name) {
        let found = false;
      
        const updatedMainSelected = mainSelected.map(x => {
          if (x.name === name) {
            found = true;
            return { ...x, quantity: Number(event.target.value) };
          }
          return x;
        });
      
        if (found) {
          setMainSelected(updatedMainSelected);
        } else {
          const updatedExtras = extras.map(x => {
            if (x.name === name) {
              return { ...x, quantity: Number(event.target.value) };
            }
            return x;
          });
          setExtras(updatedExtras);
        }
      }      

      async function handlePackageClick(name,id){
        const flipInstance = flipped.find(x => x.name === name)

        if(!flipInstance){
            const responseObj = await axios.get('api/v1/package-images/'+id)
            const images = responseObj.data

            setFlipped([...flipped,{
                name,
                isOpened: true,
                images
            }])
        }else{
            const updatedFlipped = flipped.map(item => {
                if (item.name === name) {
                    return { ...item, isOpened: !item.isOpened };
                }
                return item;
            });
    
            setFlipped(updatedFlipped);
        }
      }

      useEffect(()=>{
        const mainSum = mainSelected.reduce((x,y)=> x + (y.price * y.quantity),0)
        const extraSum = extras.reduce((x,y)=> x + (y.price * y.quantity),0)
        let price = mainSum + extraSum

        setFilteredTime(filteredTime)
        setPrice(price)
      },[mainSelected,extras])

      function renderPackageImages(name){
        const flippedInstance = flipped.find(x => x.name === name)
        if(!flippedInstance || !flippedInstance?.images?.images?.length){
            return <p>No pictures</p>
        }else{
            const style = {
                width: '100%',
                position: 'relative',
                marginBottom: '1em'
              }
            return <div className={classes.packageImgInner}>
              {
                flippedInstance.images.images.map(image => {
                  const imageUrl = image.img
                  const weaponName = image.name
                  return (
                    <div style={style} key={imageUrl}>
                      <div className={classes.flippedInstanceName}>{weaponName}</div>
                      <img alt='Weapon' style={{borderRadius: '12px'}} width={'100%'} src={imageUrl}></img>
                    </div>
                  )
                })
              }
            </div>
        }
      }

      function onPopupClose(){
        setShowPopup(false)
      }

      function getPriceText(){
       return formData.currency === 'EUR' ? 
                        eurPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").concat(' EUR') 
                        : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").concat(' HUF')
      }

  return (
    <div className={classes.packages}>
        <Helmet>
            <title>Top Gun Budapest - Package selection</title>
            <meta name="description" content="Book your slot at our shooting range and choose from our wide selection of firearms. Whether you're a beginner or an advanced shooter, we have the right gun for you." />
            <meta property="og:title" content="Top Gun Budapest - Package selection" />
            <meta property="og:description" content="Book your slot at Top Gun shooting range in Budapest and choose from a variety of firearm types, including handguns, rifles, and more." />
            <meta property="og:type" content="website" />
            <link rel="canonical" href="https://www.budapestshootingrange.hu/packages" />
          <meta property="og:image" content="https://www.budapestshootingrange.hu/thumbnail.png" />
        </Helmet>
        <h1>Shooting packages</h1>
        <h2 className="text-center md-p-0 p-5 md-pt-0 pt-3 md-pb-0 pb-0">Choose a package for your booking and select any additional extras as well.</h2>
        <div className={["row",classes.packageWrapper].join(" ")}>
            {
                (()=>{
                    if(packages.length === 0){
                        return Array.from({length: 5}).map((_,i) => <div key={i} className={["col-12","col-md-6","col-lg-3",classes.packageCardWrap].join(" ")}>
                            <div className={[classes.packageCard,classes.loaderCenter].join(' ')}><div className="spinner-border text-danger" role="status"></div></div>
                    </div>)
                    }else{
                        return packages.map((x,i)=>{
                            return (
                                <div key={i} className={["col-12","col-md-6","col-lg-3",classes.packageCardWrap,isCardOpened(x.name) ? classes.flipped : ''].join(" ")}>
                                    <div className={classes.packageCard}>
                                        <svg className={classes.star} height="512px" viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg"><path d="M316.01,199.02L256.134,14.817L196.239,199.02H1.134l158.102,113.324L98.53,496.487l157.604-114.232  l157.585,114.232l-60.687-184.143L511.134,199.02H316.01z M335.084,318.257l42.407,128.63L267.22,366.963l-11.086-8.033  l-11.086,8.033l-110.291,79.923l42.408-128.63l4.353-13.18l-11.289-8.08L59.903,217.909h136.336h13.724l4.242-13.051l41.929-128.957  l41.91,128.957l4.242,13.051h13.724h136.336l-110.327,79.088l-11.27,8.08L335.084,318.257z" fill="#F6B352"/></svg>
                                        <div>
                                            <div className={classes.packageData}>
                                                <h3>{x.name}</h3>
                                                <hr />
                                                <h5>{x.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} HUF</h5>
                                            </div>
                                            { isMobile && <button className={classes.BookNow} onClick={()=>handleNavigateToBook()}>Book now</button> }
                                            <button className={classes.InspectWeapon} onClick={()=>handlePackageClick(x.name,x.id)}><i className="bi bi-binoculars-fill"></i>Inspect weapons</button>
                                            {
                                                    isCardOpened(x.name) ? 
                                                    renderPackageImages(x.name) :
                                                    (
                                                        <div className='d-flex justify-content-center align-items-center flex-column'>
                                                            <table className={classes.packageTable}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Weapons</th>
                                                                        <th>Round type</th>
                                                                        <th>Round count</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {x.properties.map((prop,i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td> {prop.weapon} </td>
                                                                            <td> {prop.roundType} </td>
                                                                            <td> {prop.roundCount} </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                })()
            }
            <div className={["col-12","col-md-12","col-lg-9","md-mt-5","position-relative",classes.packageCardWrap].join(" ")}>
                <div className={[classes.packageLil,classes.packageCard,classes.noTransform].join(" ")}>
                    <h1> <b>EXTRA</b> PACKAGES <br /> MUST BE BOUGHT WITH AT LEAST <b>ONE</b> MAIN PACKAGE</h1>
                    <div className="row justify-content-center">
                        <div className='row'>
                            {
                                extraPackages.map((extraPackage,i) => {
                                    return (
                                        <div key={i} className="col-12 col-md-4">
                                        <div className="m-3">
                                            <h3>{extraPackage.name}</h3>
                                            <hr />
                                            <h5>HUF {extraPackage.price.toLocaleString()}</h5>
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th>Weapons</th>
                                                        <th>Round Type</th>
                                                        <th>Round count</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                     extraPackage.properties.map(weapon => {
                                                        return (
                                                        <tr key={weapon.weapon}>
                                                            <td>{weapon.weapon}</td>
                                                            <td>{weapon.roundType}</td>
                                                            <td>{weapon.roundCount}</td>
                                                        </tr>
                                                        )
                                                     })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>
                        <div id="booking" className={["col-12",classes.bookingWrap].join(' ')}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className={classes.details}>
                                        <h3>Enter your details</h3>
                                        <form className="row">
                                            <div className="col-6">
                                                <input name="firstName" value={formData.firstName} onChange={handleChange} type="text" placeholder="First name" />
                                            </div>
                                            <div className="col-6">
                                                <input name="lastName" value={formData.lastName} onChange={handleChange} type="text" placeholder="Last name" />
                                            </div>
                                            <div className="col-12">
                                                <input name="email" value={formData.email} onChange={handleChange} type="email" placeholder="Email address" />
                                            </div>
                                            <div className="col-12">
                                                <input name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} type="text" placeholder="Phone number" />
                                            </div>
                                            <div className="col-8">
                                                <input name="streetAddress" value={formData.streetAddress} onChange={handleChange} type="text" placeholder="Street Address" />
                                            </div>
                                            <div className="col-4">
                                                <input name="postCode" value={formData.postCode} onChange={handleChange} type="number" placeholder="Post Code" />
                                            </div>
                                            <div className="col-6">
                                                <input name="city" value={formData.city} onChange={handleChange} type="text" placeholder="City" />
                                            </div>
                                            <div className="col-6">
                                                <input name="country" value={formData.country} onChange={handleChange} type="text" placeholder="Country" />
                                            </div>
                                            <div className="col-12">
                                                <textarea name="message" value={formData.message} onChange={handleChange} rows="3" placeholder="Send us a message" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <h3 className="md-mb-0 mb-3">Select your packages</h3>
                                    <div>
                                        <h4>Main packages</h4>
                                        <div className={[classes.selector].join(" ")}>
                                            {packages.map((x,i) => {
                                                return (
                                                    <div className='row' key={x.name}>
                                                        <div onClick={()=>handleSelect(x.name,x.price)} className={getPackageItemClass(x.name).join(" ")}>
                                                            <p>{x.name}</p>
                                                        </div>
                                                        <div className={getPackageItemQuantityClass(x.name).join(" ")}>
                                                            {
                                                                !!mainSelected.find(item => item.name === x.name) 
                                                                && 
                                                                (<div className={classes.quantityInput}>
                                                                    <input defaultValue={1} onChange={(e)=>onPackageQuantityChange(e,x.name)} type="number" />
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="mt-3">Extra packages</h4>
                                        <div className={[classes.selector].join(" ")}>
                                            {
                                                extraPackages.map((x,i) => {
                                                    return (
                                                        <div className='row' key={x.name}>
                                                            <div key={i} onClick={()=>handleSelectExtra(x.name,x.price)}  className={getPackageItemClass(x.name).join(" ")}>
                                                                {x.name}
                                                            </div>
                                                            <div className={getPackageItemQuantityClass(x.name).join(" ")}>
                                                                {
                                                                    !!extraPackages.find(item => item.name === x.name) 
                                                                    && 
                                                                    (<div className={classes.quantityInput}>
                                                                        <input defaultValue={1} onChange={(e)=>onPackageQuantityChange(e,x.name)} type="number" />
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                  <div>
                                    <div className={[classes.promo, 'row'].join(' ')}>
                                      <div className='col-8'>
                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                          <h4 className='mt-3'>Date</h4>
                                          <h4 className={classes.availablePeopleCount}>{dayAvailability} spots
                                            available</h4>
                                        </div>
                                        <DatePicker filterDate={filterDate} selected={startDate} minDate={moment().toDate()}
                                                    onChange={(date) => setStartDate(date)}></DatePicker>
                                      </div>
                                      <div className='col-4'>
                                        <h4 className='mt-3'>What time?</h4>
                                        <select value={formData.time} name='time' onChange={handleChange}
                                                className='form-select' size='3'>
                                          {hoursArray.map((number) => {
                                            number = Number(number.split(':')[0]) || number
                                            return filteredTime.includes(number) ?
                                              null : <option key={number} value={number.toString().split(':')[0]}>
                                                {number}
                                              </option>
                                          })}
                                        </select>
                                      </div>
                                      <div className='col-8'>
                                        <div className={classes.packageCountIllustrationWrapper}>
                                          <h4 className='mt-3'>How many people?</h4>
                                          <div
                                            className={['d-flex flex-row gap-2 align-items-center', classes.packageCountIllustration].join(' ')}>
                                            <span>1x</span>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16'
                                                 fill='currentColor' className='bi bi-person-standing'
                                                 viewBox='0 0 16 16'>
                                              <path
                                                d='M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M6 6.75v8.5a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v2.75a.75.75 0 0 0 1.5 0v-2.5a.25.25 0 0 1 .5 0' />
                                            </svg>
                                            <p className='m-0'>=</p>
                                            <span>1x</span>
                                            <i className='bi bi-box-seam-fill'></i>
                                          </div>
                                        </div>
                                        <select value={formData.howManyPeople} name='howManyPeople'
                                                onChange={handleChange} className='form-select' size='3'>
                                          {numbers.map((number) => {
                                            return number <= availablePeopleCount ?
                                              <option key={number} value={number}>
                                                {number}
                                              </option> : null
                                          })}
                                        </select>
                                      </div>
                                      <div className='col-4'>
                                        <h4 className='mt-3'>Choose a currency</h4>
                                        <select value={formData.currency} name='currency' onChange={handleChange}
                                                className='form-select' size='3'>
                                          <option value='HUF'>HUF</option>
                                          <option value='EUR'>EUR</option>
                                        </select>
                                      </div>
                                      <div className='col-12'>
                                        <h4 className='mt-3'>Promo code</h4>
                                        <input name='promoCode' value={formData.promoCode} onChange={handleChange}
                                               type='text' />
                                      </div>
                                      <div className='col-12'>
                                        <div className='row'>
                                          <div className='col-8'>
                                            <div className='form-check mt-2'>
                                              <input name='termsAndConditions' value={formData.termsAndConditions}
                                                     onChange={handleChange} className='form-check-input'
                                                     type='checkbox' id='conditions' />
                                              <label className='form-check-label' htmlFor='conditions'>I agree to the <a
                                                target='_blank' href='/terms-and-conditions'>terms and
                                                conditions</a></label>
                                            </div>
                                          </div>
                                          <div className='col-4'>
                                            <h5 className={classes.price}>{getPriceText()}</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {validationError && <p
                                      className={classes.testAlertText}>{validationError || 'Something went wrong.'}</p>}
                                    <a target='_blank'
                                       href='https://simplepartner.hu/PaymentService/Payment_information.pdf'><img
                                      className={classes.acceptedCards} src={accepted_cards} width='100%'
                                      alt='accepted cards' /></a>
                                    <Popup onClose={onPopupClose} open={showPopup} className={classes.Popup} modal
                                           position="right center">
                                      <div className={classes.BookingDetails}>
                                        <div className={[classes.TopWrapper, 'row'].join(' ')}>
                                          <h3 className='col-6'>Confirm Details:</h3>
                                          <div className={[classes.Dates, 'col-6'].join(' ')}>
                                            <p className='m-0'>{moment(formData?.date).format("YYYY-MM-DD")}</p>
                                            <p className='m-0'>{formData?.time}:00</p>
                                          </div>
                                        </div>
                                        <div className={[classes.BodyWrapper, 'row'].join(' ')}>
                                          <div className={[classes.Summary, 'col-12 col-md-5'].join(' ')}>
                                            <p><strong>Name:</strong> {formData?.firstName} {formData?.lastName}</p>
                                            <p><strong>Email:</strong> {formData?.email}</p>
                                            <p><strong>Phone Number:</strong> {formData?.phoneNumber}</p>
                                            <p>
                                              <strong>Address:</strong> {formData?.streetAddress}, {formData?.postCode}, {formData?.city}, {formData?.country}
                                            </p>
                                            <p><strong>Number of People:</strong> {formData?.howManyPeople}</p>
                                            <p>
                                              <strong>Date:</strong> {moment(formData?.date).format("YYYY-MM-DD")} {formData?.time}:00
                                            </p>
                                            <p style={{ maxHeight: "200px", overflow: "auto", wordBreak: "break-word" }}>
                                              <strong>Message:</strong> {formData?.message}</p>
                                          </div>
                                          <div className='col-12 col-md-6 d-flex flex-column justify-content-between'>
                                            <div>
                                              <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>Packages:</p>
                                              <ul>
                                                {
                                                  mainSelected.concat(extras).map(pckg => {
                                                    return (
                                                      <li key={pckg.name}>
                                                        {pckg.name} ({pckg.quantity}x) <span>{(pckg.quantity * pckg.price).toLocaleString()}</span>
                                                      </li>
                                                    )
                                                  })
                                                }
                                              </ul>
                                              <hr />
                                              <div className='d-flex justify-content-between flex-row'>
                                                <p>Total:</p>
                                                <p>{getPriceText()}</p>
                                              </div>
                                            </div>
                                            <button onClick={startBook} className={classes.Book}>CONFIRM AND PAY</button>
                                          </div>
                                        </div>
                                      </div>
                                    </Popup>
                                    <button onClick={handleBook} className={classes.book}>Book now</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div>
        <p className={classes.goodToKnow}>Due to the nature of the business and wear on tear on weapons, some weapons
          are subject to availability.</p>
      </div>
    </div>
  )
}
