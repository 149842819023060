import classes from "./style.module.css"

export default function PrivacyPolicy(){

    return (
        <div className={classes.InformPage}>
            <h1>Privacy Policy</h1>
            <p>This Privacy Policy describes how budapestshootingrange.hu (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.</p>
            <h2>Collecting Personal Information</h2>
            <p>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.</p>
            <p>Order information</p>
            <ul>
                <li>Examples of Personal Information collected: name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number.</li>
                <li>Purpose of collection: to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
                <li>Source of collection: collected from you.</li>
            </ul>
            <h2>Minors</h2>
            <p>The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address below to request deletion.</p>
            <h2>Sharing Personal Information</h2>
            <p>We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:</p>
            <ul>
                <li>We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</li>
            </ul>
            <h2>Using Personal Information</h2>
            <p>We use your personal Information to provide our services to you, which includes: offering products for sale, processing payments, shipping and fulfilment of your order, and keeping you up to date on new products, services, and offers.</p>
            <h3>Lawful basis</h3>
            <p>Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic Area (“EEA”), we process your personal information under the following lawful bases:</p>
            <ul>
                <li>Your consent;</li>
                <li>The performance of the contract between you and the Site;</li>
                <li>Compliance with our legal obligations;</li>
                <li>To protect your vital interests;</li>
                <li>To perform a task carried out in the public interest;</li>
                <li>For our legitimate interests, which do not override your fundamental rights and freedoms.</li>
            </ul>
            <h3>Retention</h3>
            <p>When you place an order through the Site, we will retain your Personal Information for our records unless and until you ask us to erase this information. For more information on your right of erasure, please see the ‘Your rights’ section below.</p>
            <h3>GDPR</h3>
            <p>If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information.</p>
            <h3>Cookies</h3>
            <p>A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of different cookies, including functional, performance, advertising, and social media or content cookies. Cookies make your browsing experience better by allowing the website to remember your actions and preferences (such as login and region selection). This means you don’t have to re-enter this information each time you return to the site or browse from one page to another. Cookies also provide information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.
We use the following cookies to optimize your experience on our Site and to provide our services.
The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.
You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can negatively impact your user experience and parts of our website may no longer be fully accessible.
Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your browser controls, often found in your browser’s “Tools” or “Preferences” menu. For more information on how to modify your browser settings or how to block, manage or filter cookies can be found in your browser’s help file or through such sites as <a href=" www.allaboutcookies.org.">www.allaboutcookies.org.</a>
Additionally, please note that blocking cookies may not completely prevent how we share information with third parties such as our advertising partners. To exercise your rights or opt-out of certain uses of your information by these parties, please follow the instructions in the “Behavioural Advertising” section above.</p>
            <h3>Contact</h3>
            <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at info@budapestshootingrange.hu
            <br /><br />
            Last updated: 10.08.2021
            <br /><br />
            If you are not satisfied with our response to your complaint, you have the right to lodge your complaint with the relevant data protection authority.</p>
        </div>
    )
}