import classes from "./style.module.css";

export default function ReturnsPolicy() {
  return (
    <div className={classes.InformPage}>
      <h1>Returns</h1>
      <p>
        If you are not satisfied with your purchase, please contact us on
        info@budapestshootingrange.hu.hu a request within 10 business days of of your
        purchase. If you have a valid case we may offer you store credit, or
        offer you a refund, less 25% restocking fee, based on your original
        method of payment. If a Physical Item All products must be packed in the
        original packaging and sent back within 10 working days of purchase,
        including any accessories, manuals, documentation and registration that
        shipped with the product. A 15% open box fee in addition to the 25%
        restocking fee (totalling 40%) will be assessed on any sealed product
        that is opened or removed from its original packaging.
      </p>
      <p>
        Please note that we do not offer permit the return of the following
        products that we offer at Churchill Lövész Kft :
      </p>
      <ul>
        <li>
          Experience or shooting packages. Once compelled by the buyer, these
          products are NON refundable due to the nature of the purchase, To
          clarify, When you have used and completed a package you are no longer
          entitled to a refund on this package element.
        </li>
        <li>
          Products sold "as is" or "used" or that have been installed or used
          after receipt.
        </li>
        <li>Firearms or equipment, once transferred to buyer.</li>
        <li>
          Memberships are non refundable and MUST be canceled 10 working days
          before renewal.
        </li>
      </ul>
      <p>
        NOTE: We recommend that you use a carrier that offers shipment tracking
        for all returns, and either insure your package for safe return or
        declare the full value of the shipment so that you are protected if the
        shipment is lost or damaged in transit. If you chose not to use a
        carrier that offers tracking and insure or declare the full value of the
        product, you will be responsible for any loss or damage to the product
        during shipping.
      </p>
      <p>
        The customer is responsible for shipping charges both ways on returns,
        as well as for shipping charges on shipments that are refused.
      </p>
      <h2>Delivery Policy</h2>
      <p>
        In terms of physical products we do NOT offer a delivery service. All
        physical products MUST be collected in person.
      </p>
      <h2>Refunds / Cancelation</h2>
      <p>
        To be eligible for a 100% refund you MUST contact us on
        info@budapestshootingrange.hu 72 hours before your appointment date, there may
        be acceptations for this rule but are done on a case by case basis.
        Refunds will be issued via buyers payment method only, unless a cash
        payment was received on site, in which case Churchill Lövész Kft
        requests that you provide alternative online payment method, note: the
        account MUST be held in the same legal name or entity as the buyer.
      </p>
      <h1>Website Disclaimer</h1>
      <p>
        Other items available for sale not listed on this website include
        discontinued parts or parts for firearms no longer produced. Please
        contact us if you have any questions regarding parts for firearms no
        longer produced, its worth noting also that some weapons may be damaged
        and not available on the day of your visit. If this is the case we will
        do our best to UPGRADE the weapon in the package, so the buyer will
        always have similar or higher weapon usage. All items on this website
        are subject to change without notice. This includes item availability,
        one-of-a-kind items, and pricing.
      </p>
      <h1>Used Firearms</h1>
      <p>
        The seller provides all descriptions for used items. All descriptions
        for used items are honest and based on actual items being offered for
        sale. Items being sold in this manner will be marked as such. All sales
        on used items are final. Photos will be provided when available. All
        machine guns are considered used unless otherwise noted.
      </p>
      <h1>Customer Service</h1>
      <p>
        If you encounter any problems with your order or the checkout process,
        or if you have any questions about the status of your order, simply
        contact our customer service staff through our email on
        info@budapestshootingrange.hu
      </p>
      <h1>Order Fulfillment</h1>
      <p>
        In terms of experiences and shooting packaged All orders are considered
        fulfilled on the date of your appointment.
      </p>
      <h1>Online Price & Description Errors</h1>
      <p>
        Our online inventory changes every day. Occasionally an item may appear
        on the site by mistake or the item's description may contain a
        typographical error. We do not guarantee that titles, descriptions,
        pictures or prices on our site are error-free. We reserve the right to
        refuse any order including but not limited to orders for items with
        errors in the description or price. In the event that we cancel an order
        we will not charge the customer's credit card or we will refund the
        money. If an item's description contains an error such as incorrect
        photo, price, accessory or description and a customer makes the order,
        we will do our upmost to contact customer and rectify the issue with
        refund or upgrade of purchase.
      </p>
      <h1>CONTACT INFORMATION</h1>
      <p>
        Questions about the Terms of Service should be sent to us at
        info@budapestshootingrange.hu
      </p>
    </div>
  );
}
