const menuItems = [
    {
      displayName: "Home",
      pathname: "/",
      priority: 5
    },
    {
      displayName: "Weapons",
      pathname: "/weapons",
      priority: 4
    },
    {
      displayName: "Packages & Booking",
      pathname: "/packages",
      priority: 6
    },
    {
      displayName: "About us",
      pathname: "/about-us",
      priority: 3
    },
    {
      displayName: "Membership",
      pathname: "/membership",
      priority: 1
    },
    {
      displayName: "Contact",
      pathname: "/contact",
      priority: 2
    },
    {
      displayName: "Login",
      pathname: "/login",
      hidden: true
    },
    {
      displayName: "Bookings",
      pathname: "/admin",
      admin: true
    },
    {
      displayName: "Memberships & Contacts",
      pathname: "/memberships-contacts",
      admin: true
    },
    {
      displayName: "Settings",
      pathname: "/settings",
      admin: true
    },
    {
      displayName: "Weapons",
      pathname: "/edit/weapons",
      admin: true
    },
    {
      displayName: "Packages",
      pathname: "/edit/packages",
      admin: true
    }
  ];
  
  export default menuItems;
  