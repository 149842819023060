import React, { useContext } from 'react'
import classes from "./admin.module.css"
import AuthContext from '../../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import BookingList from './BookingList';

export default function Admin() {
    const { setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    
    const logout = () => {
        localStorage.removeItem('token')
        setIsLoggedIn(false)
        navigate("/")
    }

  return (
    <div className={classes.admin}>
        <div className='row justify-content-center flex-column align-items-center'>

            <div className={classes.bookings}>
                <div>
                    <BookingList title="Today" endpoint="api/v1/get-today-orders"></BookingList>
                </div>
            </div>
            <div className={[classes.bookings,"mt-5"].join(" ")}>
                <div>
                    <BookingList title="Upcoming" endpoint="api/v1/get-upcoming-orders"></BookingList>
                </div>
                <div className='mt-5'>
                    <BookingList title="Past Bookings" endpoint="api/v1/get-past-bookings"></BookingList>
                </div>
                <div className='mt-5'>
                    <BookingList title="Archived Bookings" endpoint="api/v1/get-archived-bookings"></BookingList>
                </div>
             </div>

            <div className='col-3 mt-5'>
                <input onClick={()=>logout()} type='button' value="Logout"></input>
            </div>
        </div>
    </div>
  )
}
