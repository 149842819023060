import React from 'react'
import classes from "./LilSites.module.css"

export default function AboutUs() {
  return (
    <div>
        <div className={classes.aboutUs}>
            <h1 className="mb-4">About us</h1>
            <p>Top Gun shooting Budapest was purpose built to offer something new to the market when it comes to a shooting package. 
            We wanted to build and experience that would stay with your long after you have left Budapest. Our range masters are all ex servicemen from various locations. 
            Our focus is more on the client rather than having a factory that wants to bleed tourists dry. Our guests reviews speak for themselves. What are you waiting for. </p>
            <p>Our main focus is that our clients have gained the maximum amount of adrenaline pumping through their veins while still operating in a safe environment. We have various instructors from various special forces around the planet so you know that the instruction given is from a true professional. So if you are a complete novice or a professional Top Gun shooting range Budapest has you covered, from handguns to assault rifles to sniper rifles...
            <a href="/packages"><b>BOOK</b></a> THE HIGHLIGHT TO YOUR TRIP TO BUDAPEST NOW!</p>
        </div>

        <div className={classes.faq}>
            <h1 className="mb-4">FAQ - Frequently asked questions</h1>
            <div>
                <div className={classes.faqBlock}>
                    <h2>Is it better to purchase what I want to shoot on the website?</h2>
                    <p>Yes, this will save time and help us cater to your needs making sure you get the best experience.</p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>Do I need to make reservation?</h2>
                    <p>Yes, in some situations we can accommodate guests of the street but if we are even remotely busy this is hard to do, the best option is to revere your time and call us if you are late.</p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>Are there any health restrictions that will prevent me from shooting?</h2>
                    <p>Please ensure that neither you nor your party is under the influence of any drugs or alcohol before coming to our facility. Although you will not be escorted off the premises, those that are under the influence will not only not be allowed to handle a firearm, but they cannot go into our range. If you are pregnant or nursing: For the safety of you and your baby, we do not allow those that are pregnant or nursing onto our range.</p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>What is the minimum age for shooting at Top Gun Shooting?</h2>
                    <p>18 years old by law, but children are allowed to enter the premises with an adult.</p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>Do I need photo identification to shoot at Top Gun Shooting?</h2>
                    <p>Yes, please bring a valid form of photo identification (ie: driver’s license, passport, military ID, etc.)</p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>Can I shoot by myself in the range?</h2>
                    <p>No, a Range Safety Officer (RSO) is present with you at all times. Each guest is assigned an RSO with a strong military background that has been independently certified to provide range instruction. Your RSO provides a safety briefing to include proper stance and positioning, proper handling, and all other range rules that are STRICTLY enforced.</p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>Can I add additional ammunition or guns?</h2>
                    <p>Yes – the extra ammunition is priced using individual weapons pricing.</p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>Is there a required dress code?</h2>
                    <p>No, but we do require that if you are coming in a stag costume please be suitable for children too, we understand you are having a good time but if you show up dressed as a penis you will not be able to shoot. </p>
                </div>
                <div className={classes.faqBlock}>
                    <h2>Can I take pictures while at Top Gun Budapest Shooting?</h2>
                    <p>Yes! As long as you tag us! Take all the pictures and video you want and use the hashtag <b>#Top Gunshootingbudapest</b> when you share them on Facebook, Twitter, or Instagram.</p>
                </div>
            </div>
        </div>
    </div>
  )
}