import React, { useState } from 'react'
import classes from './admin.module.css'
import DatePicker from 'react-datepicker'
import axios from "axios"
import { useEffect } from 'react'
import moment from "moment"

export default function Settings() {
    const [filterDate,setFilterDate] = useState( new Date() )
    const [maxPeopleCount,setMaxPeopleCount] = useState(20)
    const [promoCodes,setPromoCodes] = useState([])
    const [filterBookings,setFilterBookings] = useState([])
    const [filterTimes,setFilterTimes] = useState("")
    const [eurPrice, setEurPrice] = useState()

    const [code,setCode] = useState("")
    const [discount,setDiscount] = useState("")

    const handleDeleteFilterBookings = (i) => {
        const updatedFilterBookings = filterBookings.filter((_,ind) => ind !== i);
        setFilterBookings(updatedFilterBookings);
    }

    const handleDeleteDiscount = (i) => {
        const updatedPromoCodes = promoCodes.filter((_,ind) => ind !== i);
        setPromoCodes(updatedPromoCodes);
    }

    const handleAddDiscount = () => {
        const updatedPromoCodes = [...promoCodes, { code, percentage: discount }];
        setPromoCodes(updatedPromoCodes);
    };

    const handleAddFilterBookings = () => {
        const updatedFilterBookings = [...filterBookings, { date: moment(filterDate).format("YYYY-MM-DD"), excludesTimes: filterTimes }];
        setFilterBookings(updatedFilterBookings);
    }

    useEffect(() => {
        async function getData(){
            const token = localStorage.getItem("token");
            if (token) {
            try {
                const config = { headers: { Authorization: `Bearer ${token}` }};
                const response = await axios.get(
                `/api/v1/get-save-settings`,config
                );
                const d = response.data;
                if (d) {
                    setMaxPeopleCount(d.maximumPeopleCount);
                    setPromoCodes(d.promoCodes)
                    setFilterBookings(d.filterBookings)
                    setEurPrice(d.eurPrice)
                }
            } catch (error) {
                console.log(error);
            }
            } else {
            window.location.href = "/";
            }
        }
        getData()
    }, [])
    

    const handleSaveChanges = async() => {
        const token = localStorage.getItem("token");
        if (token) {
        try {
            const config = { headers: { Authorization: `Bearer ${token}` }};
            const body = {
                maxPeopleCount,
                filterBookings,
                promoCodes,
                eurPrice
            }
            const response = await axios.post(
            `/api/v1/save-settings`,body,config
            );
            const d = response.data;
            if (d.maxPeopleCount) {
                setMaxPeopleCount(d.maxPeopleCount);
            }
        } catch (error) {
            console.log(error);
        }
        } else {
        window.location.href = "/";
        }
    }

  return (
    <div className={classes.settings}>
        <div>
            <h1><b>Basic settings</b></h1>
            <div className="row mt-3">
                <div className="col-3 pr-1 p-0">
                    <p>Maximum people count:</p>
                    <input onChange={(e) => setMaxPeopleCount(e.target.value)} value={maxPeopleCount} type="number" />
                </div>
                <div className="col-3 pr-1 p-0">
                    <p>EUR Price:</p>
                    <input onChange={(e) => setEurPrice(e.target.value)} value={eurPrice} type="number" />
                </div>
                <div className="col-6 p-0">
                    <p>Promo codes:</p>
                    <div className='d-flex flex-column gap-1 position-relative'>
                        {
                            promoCodes.map((x, i) => {
                                return <div key={x + "-" + i} className='position-relative mb-1'>
                                    <i onClick={() => handleDeleteDiscount(i)}
                                       className={["bi", "bi-x", classes.delete].join(" ")}></i>
                                    <input type="text" disabled value={x.code + ' - ' + x.percentage + '%'} />
                                </div>
                            })
                        }
                    </div>
                    <div className='d-flex flex-row gap-1'>
                        <input value={code} onChange={(e) => setCode(e.target.value)} className='m-0' placeholder='Code'
                               type="text" />
                        <input value={discount} onChange={(e) => setDiscount(e.target.value)} className='m-0'
                               placeholder='Discount size (%)' type="number" />
                    </div>
                    <div className="w-100 p-2 justify-content-center d-flex">
                        <i onClick={() => handleAddDiscount()} className={["bi", "bi-plus", classes.add].join(" ")}></i>
                    </div>
                </div>
                <div className="col-12 p-0">
                    <p>Filter bookings:</p>
                    <div className='col-12'>
                        {
                            filterBookings.map((x, i) => {
                                return <div key={x + "-" + i} className='position-relative mb-1'>
                                    <i onClick={() => handleDeleteFilterBookings(i)}
                                       className={["bi", "bi-x", classes.delete].join(" ")}></i>
                                    <input type="text" disabled
                                           value={x.date + ' - Excludes times: ' + x.excludesTimes} />
                                </div>
                            })
                        }
                    </div>
                    <div className='col-12 d-flex flex-row gap-1'>
                        <DatePicker onChange={(e) => setFilterDate(e)} selected={filterDate}></DatePicker>
                        <input value={filterTimes} onChange={(e) => setFilterTimes(e.target.value)}
                               placeholder='For example: 11:00,13:00' type="text" />
                    </div>
                    <div className="col-12 w-100 mt-2 justify-content-center d-flex p-0">
                        <i onClick={() => handleAddFilterBookings()}
                           className={["bi", "bi-plus", classes.add].join(" ")}></i>
                    </div>
                </div>
            </div>
        </div>
        <input type="button" className={classes.saveChanges} onClick={() => handleSaveChanges()} value="Save changes" />
    </div>
  )
}
