import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './Navigation.module.css';
import hambi from './hambi.png';
import menuItems from "../menuItems.js"
import bulletBlack from "./Views/Home/bullet_black.png"
import logo from "./Views/Home/logo.png"
import AuthContext from '../../AuthContext';

export default function Navigation() {
    const location = useLocation();
    const isMobile = window.innerWidth <= 767;
    const [showMenubar,setShowMenubar] = useState(isMobile ? false : true);
    const mobile = isMobile ? classes.open : classes.nav
    const { isLoggedIn} = useContext(AuthContext);

    const clickMenu = () => {
        setShowMenubar(false)
    }

    return (
    <div className={classes.pblc}>
        <div className={classes.brand}>
            <h1> 
            <div className={classes.bullets}>
                <img src={bulletBlack} alt="bullet" />
                <img src={bulletBlack} alt="bullet" />
            </div>
            <Link to="/"><img className={classes.logo} src={logo} alt="logo" /></Link>
            
            <div className={classes.bullets_r}>
                <img src={bulletBlack} alt="bullet" />
                <img src={bulletBlack} alt="bullet" />
            </div>
            </h1>
            <div className='d-flex flex-row justify-content-center align-items-center gap-4' onClick={()=>setShowMenubar(!showMenubar)}>
                <div className={[classes.hambi, showMenubar ? classes.rotate : ''].join(' ')}><img alt="menu" src={hambi}></img></div>
                {
                  menuItems.find(x => x.pathname === location.pathname) &&
                    <p className={classes.path}>{menuItems.find(x => x.pathname === location.pathname)?.displayName}</p>
                }
            </div>
        </div>
        <ul className={showMenubar ? mobile : ''}>
            {
                menuItems.filter(x => isLoggedIn ? x.admin === true : x.admin !== true).map(item => {
                    if(item?.hidden === true) return null
                    return (
                    <li onClick={()=>clickMenu()} key={item.pathname} className={location.pathname === item.pathname ? classes.selected : ''}>
                        <Link to={item.pathname}>{item.displayName.toLocaleUpperCase()}</Link>
                    </li>
                    )
                })
            }
        </ul>
    </div>
  );
}
