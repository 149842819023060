import classes from './admin.module.css';
import {useEffect, useReducer, useState} from "react"
import axios from "axios"

export default function EditPackages() {
  const [weaponList,setWeaponList] = useState([]);
  const [packages,setPackages] = useState();
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const [isEdit,setIsEdit] = useState(false)
  const [formData,setFormData] = useState({
    packageName : '',
    packagePrice : '',
    packageItems : [],
    packageType: 'MAIN',
    selectedRoundCount: '',
    selectedRoundType: ''
  });
  const token = localStorage.getItem('token');

  useEffect(() => {
    getWeaponList()
    getPackages()
  }, []);

  const cancel = () => {
    setIsEdit(false)
    setFormData({
      packageName : '',
      packagePrice : '',
      packageItems : [],
      packageType: 'MAIN'
    })
  }

  const getWeaponList = async() => {
    const config = { headers: { Authorization: `Bearer ${token}` }};
    var wplist = await axios.get(
    `/api/v1/public-weapons`,config
    ).catch(err => err);
    wplist = wplist.data.map(x => { return {id: x._id,name: x.name} })
    setWeaponList(wplist)
    if(wplist && wplist.length > 0){
      setFormData({
        ...formData,
        selectedWeapon: wplist[0].id
      });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addPackageItem = () => {
    const packageItem = {
      weapon: { id: formData.selectedWeapon, name: (weaponList.find(x => x.id === formData.selectedWeapon))?.name},
      roundType:formData.selectedRoundType,
      roundCount: formData.selectedRoundCount,
      packageType: formData.packageType
    };
    const formDataClone = formData;
    if(!formDataClone?.packageItems) formDataClone.packageItems = []
    formDataClone.packageItems.push(packageItem);
    setFormData(formDataClone);
    forceUpdate();
  }

  const getPackages = async() => {
    const rqst = await axios.get(
      `/api/v1/packages`
      ).catch(err => err);
    setPackages(rqst.data);
  }

  const editPackage = async(id) => {
    const _package = packages.find(x => x._id === id);
    if(_package){
      document.getElementById('editPackages').scrollIntoView({behavior:'smooth'})
      setIsEdit(true);
      setFormData(
        {
          selectedWeapon: weaponList[0].id,
          packageId: id,
          packageType: _package.packageType,
          packageName: _package.name,
          packagePrice: _package.price,
          packageItems: _package.weapons.map(x => {
            return {
              roundCount: x.roundCount,
              roundType: x.roundType,
              weapon: {
                id: x.weapon?._id,
                name: x.weapon?.name
              }
            }
          })
        }
      );

    }
  }

  const deletePackageItem = (index) => {
    var formDataPackageItemsClone = formData.packageItems
    formDataPackageItemsClone = formDataPackageItemsClone.filter((x,i) => i !== index)
    setFormData(
      {
        ...formData,
        packageItems: formDataPackageItemsClone
      }
    )
  }

  const deletePackage = async(id) => {
    const isSure = window.confirm('Are you sure you want to delete this package?');
    if(!isSure) return;

    const config = { headers: { Authorization: `Bearer ${token}` }};
    await axios.delete(`/api/v1/packages/`+id,config).catch(err => err);
    getPackages();
  }

  const savePackage = async (isEdit) => {
    const sendableBody = {
      name: formData.packageName,
      price: Number(formData.packagePrice),
      packageType: formData.packageType,
      weapons: formData.packageItems.map(x => { return {...x,weapon: x.weapon.id,name: x.weapon.name} })
    }
    const config = { headers: { Authorization: `Bearer ${token}` }};

    if(isEdit && formData?.packageId){

      await axios.put(
        `/api/v1/packages/`+formData.packageId,sendableBody,config
        ).catch(err => err);

      await getPackages();
    }else{
      await axios.post(
      `/api/v1/package`,sendableBody,config
      ).catch(err => err);

      getPackages()
      setFormData({
        packageItems: [],
        packageName: "",
        packagePrice: "",
        selectedRoundCount: "",
        selectedRoundType: "",
        selectedWeapon: weaponList[0]._id
      })
    }
  }

  return (
    <div id="editPackages" className={[classes.editPackages].join(' ')}>
        <div className='row'>
          <div className='col-12 mb-3'>
            <h1><b>{isEdit ? 'Edit' : 'Add'} package</b></h1>
          </div>
          <div>
            <form className="row">
              <div className="col-4">
                <label>Package name</label>
                <input onChange={handleInputChange} value={formData.packageName} name="packageName" type="text" />
              </div>
              <div className="col-4">
                <label>Package price (HUF)</label>
                <input onChange={handleInputChange} value={formData.packagePrice} name="packagePrice" type="number" />
              </div>
              <div className="col-4">
                <label>Package type</label>
                <select value={formData.packageType} name="packageType" onChange={handleInputChange}>
                  <option value="MAIN">Main package</option>
                  <option value="EXTRA">Extra package</option>
                </select>
              </div>
              <div className="col-12 mb-2">
                <label>Package items</label>
              </div>
              {formData?.packageItems?.length === 0 && <div className={classes.empty}>
                  Empty
              </div>}
              {
                formData?.packageItems && formData.packageItems.map((packageItem,index) => {
                  return (
                    <div key={(packageItem?.weapon?.id || packageItem?.weapon?._id)+index} className='col-12 mb-2'>
                      <div className={[classes.packageItem,"d-flex","flex-row","row"].join(' ')}>
                        <p className='m-0 col-3'>Weapon: {packageItem?.weapon?.name}</p>
                        <p className='m-0 col-3'>Round type: {packageItem.roundType}</p>
                        <p className='m-0 col-3'>Round count: {packageItem.roundCount}</p>
                        <p className={classes.packageItemDelete} onClick={()=>deletePackageItem(index)}>delete</p>
                      </div>
                    </div>
                  )
                })
              }
              <div className='col-3'>
                <label>Weapon</label>
                <select defaultValue={weaponList[0]} value={formData.selectedWeapon} onChange={handleInputChange} name="selectedWeapon">
                  {
                    weaponList.map(wp => {
                      return (
                        <option key={wp.id} value={wp.id}>
                          {wp?.name}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
              <div className='col-3'>
                <label>Round type</label>
                <input onChange={handleInputChange} value={formData.selectedRoundType} name="selectedRoundType" type="text"></input>
              </div>
              <div className='col-3'>
                <label>Round count</label>
                <input onChange={handleInputChange} value={formData.selectedRoundCount} name="selectedRoundCount" type="number"></input>
              </div>
              <div className='col-3'>
                <input type="button" onClick={addPackageItem} value="Add package item" />
              </div>
              <hr />
              <div className="col-3 d-flex flex-row gap-3">
                <input type="button" onClick={()=>savePackage(isEdit)} value={isEdit ? "Save changes" :"Save package"} />
                {isEdit && <input type="button" onClick={()=>cancel()} className='secondary' value="Cancel" />}
              </div>
              <div className="col-12 mt-5">
                <h1><b>Packages</b></h1>
              </div>
              <div className='col-12'>
                  <div className="row">
                    {
                      packages && packages.map(package_ => {
                        return (
                          <div key={package_._id} className="col-6 mb-4">
                            <div className={classes.packageBox}>
                              <div className='d-flex flex-row gap-2 justify-content-between'>
                                <h4 className='p-1'>{package_.name} {package_.packageType === 'EXTRA' && '- Extra'}</h4>
                                <h4 className='p-1'><b>{package_.price.toLocaleString()} HUF</b></h4>
                              </div>
                              <div className={classes.packageBody}>
                                <div className='row'>
                                  <p className='col-4'><b>Weapon</b></p>
                                  <p className='col-4'><b>Round Type</b></p>
                                  <p className='col-4'><b>Round Count</b></p>
                                </div>
                                {   package_?.weapons && package_.weapons.map((weapon_,i) => {
                                      return <div key={weapon_.weapon?._id + i} className='row'>
                                        <p className='col-4'>{weapon_.weapon?.name}</p>
                                        <p className='col-4'>{weapon_.roundType}</p>
                                        <p className='col-4'>{weapon_.roundCount}</p>
                                      </div>
                                    })
                                }
                                <div>
                                  <input onClick={()=>editPackage(package_._id)} type="button" className='m-0 mt-4' value="Edit package" />
                                  <input onClick={()=>deletePackage(package_._id)} type="button" className='secondary' value="Delete package" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
              </div>
            </form>
          </div>
        </div>
    </div>
  );
}
