import React, { useContext, useState } from 'react'
import classes from "./Home/Home.module.css"
import axios from "axios"
import AuthContext from "../../../AuthContext"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const navigate = useNavigate();
    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")
    const [invalid,setInvalid] = useState(false)
    const { isLoggedIn,setIsLoggedIn } = useContext(AuthContext);

    const login = async () => {
        try {
          const response = await axios.post('/login', { username, password });
          const rs = response.data;
          if (rs) {
            setInvalid(false);
            setIsLoggedIn(true);
            localStorage.setItem("token",rs.token);
            navigate('/admin');
          }
        } catch (error) {
          setInvalid(true);
          console.error('Login error:', error);
        }
      };
      

  return (
    <div className={classes.login}>
      <div className='row p-5 justify-content-center'>
      {!isLoggedIn ? (        
            <div className='col-12 col-md-4 col-lg-3'>
                <input className='mb-3' onChange={(e)=>setUsername(e.target.value)} value={username} type="text" placeholder='username' />
                <input onChange={(e)=>setPassword(e.target.value)} value={password} type="password" placeholder='password' />
                {invalid && <p className={classes.error}>Invalid credentials</p>}
                <input onClick={()=> login()} type="button" value="Login" />
            </div>
       )
        : (
        <div className='col-12 col-md-4 col-lg-4'>
          <h1>You already logged in.</h1>
          <Link to="/admin"><input type="button" value="Go to admin page" /></Link>
        </div>
        )
      } 
      </div>
    </div>
  )
}
