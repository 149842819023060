import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import classes from "./admin.module.css";
import moment from "moment";
import axios from "axios";
import { Calendar, momentLocalizer } from "react-big-calendar";
const localizer = momentLocalizer(moment);

const BookingsList = ({ endpoint, title }) => {
  const [loading,setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [isListView, setIsListView] = useState(
    localStorage.getItem(`${title}-isListView`)
      ? localStorage.getItem(`${title}-isListView`) === "true"
      : true
  );
  const [selected, setSelected] = useState({});
  const [eventList, setEventList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [show, setShow] = useState(true);
  const itemsPerPage = 5;
  const [search,setSearch] = useState("")

  const [hidden, setHidden] = useState(
    localStorage.getItem('hide_list_'+title.trim())
      ? localStorage.getItem('hide_list_'+title.trim()) === "true"
      : true
  );

  function createCustomDate(date, time) {
    const customDate = new Date(date);
    const hour = Math.floor(time);
    const minute = (time - hour) * 60;
    customDate.setHours(hour, minute, 0, 0);
    return customDate;
  }

  useEffect(() => {
    if (show) {
      document.querySelector("body").style.maxHeight = "100vh";
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.maxHeight = "unset";
      document.querySelector("body").style.overflow = "unset";
    }
  }, [show]);

  useEffect(() => {
    setShow(!show);
    // eslint-disable-next-line
  }, [selected]);

  const handleSelectEvent = (e) => {
    const _self = e.self;
    setSelected(_self);
  };

  const getCalendarData = async (e) => {
    const { start, end } = e;
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(
          `/api/v1/get-bookings?start=${moment(
            start
          ).format("YYYY-MM-DD")}&end=${moment(end).format("YYYY-MM-DD")}`,
          config
        );
        const d = response.data;
        if (d) {
          setEventList(
            d.map((x) => {
              return {
                start: createCustomDate(
                  moment(x.date).format("YYYY-MM-DD"),
                  x.time
                ),
                end: createCustomDate(
                  moment(x.date).format("YYYY-MM-DD"),
                  x.time + 1
                ),
                title: x.firstName + " " + x.lastName + " - " + x.phoneNumber,
                self: x,
              };
            })
          );
        } else {
          setBookings([]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      window.location.href = "/";
    }
  };


  async function fetchData() {
    setLoading(true)
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(
          `/${endpoint}?page=${currentPage}&limit=${itemsPerPage}&search=${search}`,
          config
        );
        const d = response.data;
        if (d.totalItems) setTotalItems(d.totalItems);
        if (d.totalPages) setPageCount(d.totalPages);
        if (d.bookings) {
          setBookings(d.bookings);
        } else {
          setBookings([]);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false)
    } else {
      window.location.href = "/";
    }
  }

  function handleArchivePackage(pkg){
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        axios.post(
          `/api/v1/archive-package`, { id: pkg._id },
          config
        ).then(_ => {
          setSelected({})
          fetchData()
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      window.location.href = "/";
    }
  }

  function handleHideList(){
    setHidden(!hidden)
    localStorage.setItem('hide_list_'+title.trim(), !hidden)
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("month").toDate();
    const end = currentDate.clone().endOf("month").toDate();

    if (isListView == false && eventList.length == 0) {
      getCalendarData({ start, end });
    }
    localStorage.setItem(`${title}-isListView`, isListView);
  }, [isListView]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  if(hidden){
    return <h1 className="mb-2 col-4 d-flex gap-3">
      <b>{title}</b>
      <i onClick={handleHideList} style={{cursor: "pointer", zIndex: 10000}} className="pointer bi bi-eye-slash-fill"></i>
    </h1>
  }

  return (
    <>
      <div className="row">
            <h1 className="mb-2 col-4 d-flex gap-3">
              <b>{title}</b>
              <i onClick={handleHideList} style={{cursor: "pointer", zIndex: 10000}} className="pointer bi bi-eye-fill"></i>
            </h1>
            <div
              className={[
                "col-4",
                "d-flex",
                "flex-row",
                "gap-2",
                "justify-content-end",
                "align-items-center",
                classes.listIcons,
              ].join(" ")}
            >
              <i
                onClick={() => setIsListView(true)}
                style={{ opacity: isListView ? 1 : "0.5" }}
                className="bi bi-list mb-3"
              ></i>
              <i
                onClick={() => setIsListView(false)}
                style={{ opacity: !isListView ? 1 : "0.5" }}
                className="bi bi-calendar mb-3"
              ></i>
            </div>
            <div className="col-4 d-flex gap-2 flex-row">
                <input type="text" value={search} className={classes.search} onChange={(e)=>setSearch(e.target.value)} placeholder="search..." />
                <input type="button" onClick={()=>fetchData()} className={classes.searchButton} value="Search" />
            </div>
      </div>

      {
        loading && <div className="col-12 d-flex justify-content-center align-items-center p-3">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
      }
      {bookings.length > 0 && (
        <div
          style={{
            opacity: show ? 1 : 0,
            pointerEvents: show ? "unset" : "none",
          }}
          className={classes.popUpWrapper}
        >
          <div
            onClick={() => setSelected({})}
            className={classes.backdrop}
          ></div>
          <div className={classes.popUp}>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="col-12">
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>Booking Number:</b>
                    </p>
                    <p>#{selected.orderNumber}</p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>Booking status:</b>
                    </p>
                    <p>{selected.status}</p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>Date:</b>
                    </p>
                    <p>{moment(selected.date).format("YYYY-MM-DD")}</p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>Time:</b>
                    </p>
                    <p>{selected.time + ":00"}</p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>People count:</b>
                    </p>
                    <p>{selected.howManyPeople}</p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>Paid amount:</b>
                    </p>
                    <p>{selected?.amount?.toLocaleString()} {selected?.currency}</p>
                  </div>
                  <div className="d-flex flex-column gap-2 justify-content-between">
                    <p>
                      <b>Selected packages:</b>
                    </p>
                    <div className="d-flex flex-column jusitfy-content-end">
                      {selected?.selected?.map((x) => (
                        <p key={x.name}>{x.name} (x{x.quantity})</p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-12"></div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-row gap-2 justify-content-between">
                  <p>
                    <b>Name:</b>
                  </p>
                  <p>{selected.firstName + " " + selected.lastName}</p>
                </div>
                <div className="d-flex flex-row gap-2 justify-content-between">
                  <p>
                    <b>Phone number:</b>
                  </p>
                  <p>{selected.phoneNumber}</p>
                </div>
                <div className="d-flex flex-row gap-2 justify-content-between">
                  <p>
                    <b>Email address:</b>
                  </p>
                  <p>{selected.email}</p>
                </div>
                <div>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>Address:</b>
                    </p>
                    <p>
                      {selected.country +
                        ", " +
                        selected.postCode +
                        " " +
                        selected.city}
                    </p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-between">
                    <p>
                      <b>Street:</b>
                    </p>
                    <p>{selected.streetAddress}</p>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2 justify-content-between">
                  <p>
                    <b>Message:</b>
                  </p>
                  <p>{selected.message}</p>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-4">
                  <input onClick={()=> handleArchivePackage(selected)} type="button" value="Archive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isListView ? (
        bookings.map((book, i) => {
          return (
            <div
              onClick={() => setSelected(book)}
              key={book.date + i}
              className={["col-12", classes.booking, "mt-3"].join(" ")}
            >
              <div
                onClick={() => setSelected(book)}
                className="d-flex flex-row justify-content-between"
              >
                <h3>
                  <b>{book.firstName + " " + book.lastName}</b> -{" "}
                  {book.phoneNumber}
                </h3>
                <div
                  onClick={() => setSelected(book)}
                  className="d-flex flex-row gap-4"
                >
                  <div className="d-flex flex-row gap-2 justify-content-center align-items-center">
                    <i className="bi bi-person-fill"></i>
                    <p>{book.howManyPeople}</p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-center align-items-center">
                    <i className="bi bi-calendar"></i>
                    <p>{moment(book.date).format("YYYY-MM-DD")}</p>
                  </div>
                  <div className="d-flex flex-row gap-2 justify-content-center align-items-center">
                    <i className="bi bi-clock"></i>
                    <p>{book.time}:00</p>
                  </div>
                </div>
              </div>
              <div className={classes.bgRadius}>
                {book.selected.map((x) => {
                  return <p key={x}>{x.name} (x{x.quantity})</p>;
                })}
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <Calendar
            localizer={localizer}
            onRangeChange={(e) => getCalendarData(e)}
            startAccessor="start"
            onSelectEvent={(e) => handleSelectEvent(e)}
            endAccessor="end"
            events={eventList}
            style={{ height: "65vh" }}
          />
        </div>
      )}
      {isListView && (
        <div className="d-flex flex-row justify-content-between">
          {bookings.length > 0 ? (
            <>
              <p className={classes.itemsText}>{totalItems} item(s)</p>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </>
          ) : (
            <div className="info">No booking found.</div>
          )}
        </div>
      )}
    </>
  );
};

export default BookingsList;
